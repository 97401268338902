<template>
  <div>
    <form @submit.prevent="updateDetail(deficiency,'corrective')">
      <div class="row justify-content-end">
        <div class="col-auto"
             v-if="isAllowed('psc.main.deficiency.overview.ca.edit') && isOwnVessel('pscReport')">
          <template v-if="toEdit">
            <button class="e-btn e-btn-outlined-red ml-2" @click="cancelUpdate('corrective')" type="button">Cancel</button>
            <button class="e-btn e-btn-blue ml-2" type="submit">Save</button>
          </template>
          <template v-else>
            <button class="e-btn e-btn-green ml-2 px-3" @click="editDetail" type="button">Edit Detail</button>
          </template>
        </div>
        <div class="col-auto" v-if="1 === 0 && isAllowed('psc.main.deficiency.overview.ca.download') && isOwnVessel('pscReport')">
          <div class="dropdown dropright mb-2">
            <button
              id="actionDropdown"
              aria-expanded="false"
              aria-haspopup="true"
              class="e-btn e-btn-blue-gray force-white-all"
              data-toggle="dropdown"
            >
              <font-awesome-icon class="mr-1" icon="download"/> Download Corrective Action Report
            </button>
            <div aria-labelledby="actionDropdown" class="dropdown-menu">
              <button class="dropdown-item text-secondary font-weight-bolder" type="button">
                <font-awesome-icon icon="file-excel" />
                Excel
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button">
                <font-awesome-icon icon="file-pdf" />
                PDF
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="w-100">
        <tr>
          <td colspan="2">
            <h6 class="font-weight-bold">Root Cause</h6>
          </td>
        </tr>
        <tr>
          <td style="width: 25%" class="pl-2">a) Root Cause</td>
          <td>{{deficiency.cause.root_cause_id ? deficiency.cause.root_cause.name.toUpperCase() : ''}}</td>
        </tr>
        <tr>
          <td style="width: 25%" class="pl-5">b) Factor of Root Cause</td>
          <td>{{deficiency.cause.root_cause_child_id ? deficiency.cause.root_cause_child.name.toUpperCase() : ''}}</td>
        </tr>
      </table>
      <hr />
      <h6 class="font-weight-bold pt-3">Preparation of Corrective Actions</h6>
      <table class="w-100">
        <tr>
          <td class="align-middle">Attached Photo Before Rectified</td>
          <td>
            <div class="row mt-2">
              <div  v-if="beforeRecAttachments.length" class="col-lg-2 col-md-4 col-sm-12 mb-1 justify-content-center align-content-center"
                    v-for="(attachment, attachmentIndex) in beforeRecAttachments">
                <psc-image-component :type="4"
                                     view-permission="psc.main.deficiency.overview.attachments-view-download"
                                     download-permssion="psc.main.deficiency.overview.attachments-view-download"
                                     @viewImage="viewImage(attachmentIndex, beforeRecAttachments, {
                                         'path'      : 'thumbnail',
                                         'caption'   : 'name'
                                       })"
                                     :imageInfo="attachment">
                </psc-image-component>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="100%">
            <hr>
          </td>
        </tr>
        <tr>
          <td style="width: 25%" class="text-danger font-weight-bold">Instruction to the Master to take corrective action <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td>
            <vue-editor v-model="deficiency.corrective.instruction" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
            </vue-editor>
            <div v-else class="pre-wrap-txt textarea-display-card my-2"
                 v-html="deficiency.corrective.instruction || ''"/>
          </td>
        </tr>
        <tr>
          <td style="width: 25%">Date of Instruction  <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td>
            <input type="date" v-if="toEdit" min="1900-01-01" v-model="deficiency.corrective.instruction_date" class="form-control form-control-sm w-auto mb-2">
            <span v-else>{{deficiency.corrective.instruction_date | moment('D MMM YYYY') }}</span>
          </td>
        </tr>
        <tr>
          <td>Instructed by (Person In Charge)</td>
          <td class="text-capitalize font-weight-bold">
            {{pscReport.general.person_in_charge_id ? pscReport.general.person_in_charge.name : ''}}
          </td>
        </tr>
        <tr>
          <td colspan="100%">
            <hr>
          </td>
        </tr>
        <tr>
          <td style="width: 25%">
            Attached any other instruction
            <template v-if="toEdit">
              <button  type="button" class="e-btn btn-xs e-btn-green ml-2 px-5 e-text-white"
                       data-toggle="modal"
                       v-if="isAllowed('psc.main.deficiency.overview.ca.attachments-create') && isOwnVessel('pscReport')"
                       data-target="#correctiveAttachments"
                       @click="setAttachmentType(2)">
                <font-awesome-icon class="mr-1" icon="plus"/>
                Add Attachment
              </button>
            </template>
          </td>
          <td>
            <div class="row py-2" >
              <div class="col-12 text-right"  v-if="toEdit">
<!--                <button type="button" class="e-btn btn-xs e-btn-green ml-2 px-5 e-text-white"-->
<!--                        data-toggle="modal"-->
<!--                        v-if="isAllowed('psc.main.deficiency.overview.ca.attachments-create') && isOwnVessel('pscReport')"-->
<!--                        data-target="#correctiveAttachments"-->
<!--                        @click="setAttachmentType(2)">-->
<!--                  <font-awesome-icon class="mr-1" icon="plus"/>-->
<!--                  Add Attachment-->
<!--                </button>-->
                <button type="button" class="e-btn btn-xs e-btn-blue ml-2 px-5 e-text-white"
                        v-if="isAllowed('psc.main.deficiency.overview.ca.attachments-download-all') && isOwnVessel('pscReport') && preparationAttachments.length"
                        @click="downloadAll(2,deficiency,'corrective.id')">
                  <font-awesome-icon class="mr-1" icon="download"/>
                  Download All Documents
                </button>
              </div>
              <div class="col-12">
                <psc-attachment :attachments="preparationAttachments"
                                :to-edit="toEdit"
                                style="height: 15vh;"
                                :type="2"
                                view-permission="psc.main.deficiency.overview.attachments-view-download"
                                download-permssion="psc.main.deficiency.overview.attachments-view-download"
                                delete-permission="psc.main.deficiency.overview.ca.attachments-delete"
                                @deleteEmitter="deleteCorrectiveAttachment"
                >
                </psc-attachment>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <hr />
      <h6 class="font-weight-bold">Report of Corrective Actions taken (or to be taken immediately and to be reported with one(1) month)</h6>
      <div class="w-100 pl-3">
        <table class="w-100">
          <tr>
            <td style="width: 25%" class="text-danger font-weight-bold">Action taken by the Management office and/or the Master <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <vue-editor v-model="deficiency.corrective.action_taken" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
              </vue-editor>
              <div v-else class="pre-wrap-txt textarea-display-card my-2"
                   v-html="deficiency.corrective.action_taken || ''"/>
            </td>
          </tr>
          <tr>
            <td style="width: 25%" class="text-danger font-weight-bold">Status <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <template v-if="editStatus">
                <div class="row align-content-center align-items-center">
                  <div class="col-auto">
                    <select class="form-control form-control-sm" v-model="reportStatus.deficiency.report_status_log.report_status_id">
                      <template v-for="reportStatusKey in reportStatusKeysPerReport.filter(status=>status.id!=5)">
                        <option v-if="reportStatusKey.visible" :value="reportStatusKey.id" :disabled="reportStatusKey.disabled">
                          {{ reportStatusKey.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-success btn-xs mr-2"
                            :disabled="currentStatus===reportStatus.deficiency.report_status_log.report_status_id" @click="saveStatus">
                      <font-awesome-icon icon="save" class="force-white-all">

                      </font-awesome-icon>
                      Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" @click="editStatus=false;">
                      <font-awesome-icon icon="ban" class="force-white-all"></font-awesome-icon>
                      Cancel
                    </button>
                  </div>
                </div>
              </template>
              <button v-else type="button" class="e-btn e-btn-xs px-5" @click="editReportStatus" :class="['bg-'+getByKey(deficiency,'report_status_color')]">
                {{ getByKey(deficiency,'report_status_name') | toUpper }}
              </button>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Date of Report <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="pt-2">
              <input type="date" v-if="toEdit" min="1900-01-01" v-model="deficiency.corrective.action_date" class="form-control form-control-sm w-auto mb-2">
              <span v-else>{{deficiency.corrective.action_date | moment('D MMM YYYY') }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Reported by <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="pt-2">
              <input type="text" class="form-control form-control-sm" v-if="toEdit"
                     v-model="deficiency.corrective.reported_by">
              <span v-else>{{ deficiency.corrective.reported_by }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="100%">
              <hr>
            </td>
          </tr>
          <tr>
            <td>Attached Photo After Rectified</td>
            <td>
              <div class="row mt-2">
                <div  v-if="afterRecAttachments.length" class="col-lg-2 col-md-4 col-sm-12 mb-1 justify-content-center align-content-center"
                      v-for="(attachment, attachmentIndex) in afterRecAttachments">
                  <psc-image-component :type="5"
                                       view-permission="psc.main.deficiency.overview.attachments-view-download"
                                       download-permssion="psc.main.deficiency.overview.attachments-view-download"
                                       @viewImage="viewImage(attachmentIndex, afterRecAttachments, {
                                         'path'      : 'thumbnail',
                                         'caption'   : 'name'
                                       })"
                                       :imageInfo="attachment">
                  </psc-image-component>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="100%">
              <hr>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Attached any other detailed report
              <template v-if="toEdit">
                <button type="button" class="e-btn btn-xs e-btn-green ml-2 px-5 e-text-white"
                        v-if="isAllowed('psc.main.deficiency.overview.ca.attachments-create') && isOwnVessel('pscReport')"
                        data-toggle="modal" data-target="#correctiveAttachments" @click="setAttachmentType(3)">
                  <font-awesome-icon class="mr-1" icon="plus"/>
                  Add Attachment
                </button>
              </template>
            </td>
            <td>
              <div class="row">

                <div class="col-12 py-2 text-right" v-if="toEdit">
<!--                  <button type="button" class="e-btn btn-xs e-btn-green ml-2 px-5 e-text-white"-->
<!--                          v-if="isAllowed('psc.main.deficiency.overview.ca.attachments-create') && isOwnVessel('pscReport')"-->
<!--                          data-toggle="modal" data-target="#correctiveAttachments" @click="setAttachmentType(3)">-->
<!--                    <font-awesome-icon class="mr-1" icon="plus"/>-->
<!--                    Add Attachment-->
<!--                  </button>-->
                  <button type="button" class="e-btn btn-xs e-btn-blue ml-2 px-5 e-text-white"
                          v-if="isAllowed('psc.main.deficiency.overview.ca.attachments-download-all') && isOwnVessel('pscReport') && verificationAttachments.length"
                          @click="downloadAll(3,deficiency,'corrective.id')">
                    <font-awesome-icon class="mr-1" icon="download"/>
                    Download All Documents
                  </button>
                </div>
                <div class="col-12">
                  <psc-attachment :attachments="verificationAttachments"
                                  :to-edit="toEdit"
                                  style="height: 15vh;"
                                  :type="3"
                                  view-permission="psc.main.deficiency.overview.attachments-view-download"
                                  download-permssion="psc.main.deficiency.overview.attachments-view-download"
                                  delete-permission="psc.main.deficiency.overview.ca.attachments-delete"
                                  @deleteEmitter="deleteCorrectiveAttachment"
                  >

                  </psc-attachment>

                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <hr />
      <h6 class="font-weight-bold">Verification of effectiveness for Corrective Action</h6>
      <div class="w-100 pl-3">
        <table class="w-100">
          <tr>
            <td style="width: 40%">Corrective action is affective to keep suitable level appropriate to the risk of problem encountered. (Case is completed and closed)</td>
            <td class="pt-2">
              <select class="form-control w-auto form-control-sm my-2" @change="correctiveActionAffectiveness" :disabled="!toEdit" v-model="deficiency.corrective.is_corrective_action_affective">
                <option :value="0">YES</option>
                <option :value="1">NO</option>
              </select>
            </td>
          </tr>
        </table>
        <template v-if="deficiency.corrective.is_corrective_action_affective">
          <table class="w-100 mt-3">
            <tr>
              <td style="width: 40%">Verification of effectiveness for Corrective Action shall be carried out again because of difficulties to do so:</td>
              <td>
                <select class="form-control w-auto form-control-sm my-2" :disabled="!toEdit" v-model="deficiency.corrective.is_corrective_action_effective">
                  <option :value="0">NO</option>
                  <option :value="1">YES</option>
                </select>
              </td>
            </tr>
            <tr v-if="deficiency.corrective.is_corrective_action_effective">
              <td style="width: 40%" class="text-right pr-3">If Yes, the date of next verification</td>
              <td>
                <input type="date" min="1900-01-01" v-if="toEdit" v-model="deficiency.corrective.verification_effective_date" class="form-control form-control-sm w-auto mb-2">
                <span v-else>{{deficiency.corrective.verification_effective_date | moment('D MMM YYYY') }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="pt-3"></td>
            </tr>
            <tr>
              <td style="width: 40%">Effectiveness for Corrective Action has not been confirmed, therefore further investigation of root cause, planning and instruction of another corrective action to be done.</td>
              <td>
                <select class="form-control w-auto form-control-sm my-2" :disabled="!toEdit" v-model="deficiency.corrective.is_verification_effective">
                  <option :value="0">NO</option>
                  <option :value="1">YES</option>
                </select>
              </td>
            </tr>
          </table>
        </template>
        <table class="w-100 mt-3">
          <tr>
            <td style="width: 25%">
              Date of Verification
            </td>
            <td class="pr-2">
              <input type="date" min="1900-01-01" v-if="toEdit" v-model="deficiency.corrective.verification_date" class="form-control form-control-sm w-auto mb-2">
              <span v-else>{{deficiency.corrective.verification_date | moment('D MMM YYYY') }}</span>
            </td>
            <td style="width: 50%">
              <div class="row w-100 align-items-center">
                <div class="col-xl-3 col-lg-4 col-md-6">P.I.C. or Superintendent</div>
                <div class="col-xl-9 col-lg-8 col-md-6">
                  {{pscReport.general.person_in_charge_id ? pscReport.general.person_in_charge.name : ''}}
                </div>
              </div>
              <div class="row w-100 align-items-center">
                <div class="col-xl-3 col-lg-4 col-md-6">
                  D.P.
                </div>
                <div class="col-xl-9 col-lg-8 col-md-6">
                  <p class="bg-light rounded mb-0 p-2 font-weight-bold">
                    Takimoto , S.
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </form>
    <div class="modal fade" id="correctiveAttachments" tabindex="-1">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Add Attachments</h6>
            <button type="button" class="close"  ref="closeCorrectiveAttachments" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input type="file" ref="attachments" @change="addCorrectiveAttachments" name="attachments" id="attachments" multiple>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

<!--    FOR IMAGE SLIDER OF ATTACHMENTS   -->
    <image-slider
      :selected-image-index="selectedImageIndex"
      :images="sliderImages"
      :visible="showSlider"
      @hideSlider="resetSlider()"
    >
    </image-slider>
  </div>


</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "../../../../services/AlertService";
import ObjectMixin from "../../../../mixins/ObjectMixin";
import AttachmentMixin from "../../../../mixins/attachmentMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import pscMixin from "../../../../mixins/PscDeficiencyMixin";
import PscAttachment from "../PscAttachment.vue";
import PscImageComponent from "@/components/modules/psc/PscImageComponent.vue";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";

export default {
  name: "PscDeficiencyCorrectiveAction",
  props:{
    corrective:{
      type:Object,
      default:null
    }
  },
  mixins:[ObjectMixin,AttachmentMixin,permissionMixin,pscMixin, ImageSliderMixin],
  components:{PscImageComponent, PscAttachment, ImageSlider},
  computed:{
    ...mapGetters(['pscReport','beforeRecAttachments','preparationAttachments','afterRecAttachments','verificationAttachments','reportStatusKeys']),
    reportStatusKeysPerReport() {
      let newReportStatusKeys = [];
      let deficiencyId = this.deficiency.id;
      let deficiencyIndex = this.pscReport.psc_deficiencies.findIndex(item => item['id'] === deficiencyId)

      let currentDeficiencyRowElement = $('#deficiency_' + parseInt(deficiencyIndex + 1));
      let nextDeficiencyRowElement = $('#deficiency_' + parseInt(deficiencyIndex + 2));

      let tableRowsBetween = currentDeficiencyRowElement.nextUntil(nextDeficiencyRowElement).not('[data-report-key="report_status"]');



      const CLOSED = 3;
      const READY_TO_CLOSE = 4;
      const OPEN = 2;

      const reportStatusId = this.deficiency.report_status_log.report_status_id;


      this.reportStatusKeys.map((data) => {
        const reportStatus = Object.assign({}, data);
        if(reportStatusId===1) {
          if (reportStatus.id === READY_TO_CLOSE) {
            reportStatus.disabled = $(tableRowsBetween).find('[data-icon="times"]').length > 0;
          }
        }
        if (reportStatusId === OPEN) {
          if (reportStatus.id === OPEN) {
            reportStatus.name = 'OPEN';
          }
          if (reportStatus.id === READY_TO_CLOSE) {
            reportStatus.visible = true;
            reportStatus.disabled = $(tableRowsBetween).find('[data-icon="times"]').length > 0;
          }
          if (reportStatus.id === 1) {
            reportStatus.visible = false;
          }
          if (reportStatus.id === CLOSED) {
            reportStatus.disabled = true;
            reportStatus.visible = false;
          }
        }
        else if (reportStatusId === READY_TO_CLOSE) {
          if (reportStatus.id === READY_TO_CLOSE) {
            reportStatus.visible = true;
            reportStatus.disabled = false;
          }
          if (reportStatus.id === CLOSED) {
            reportStatus.disabled = false;
            reportStatus.visible = true;
          }
          if (reportStatus.id === 2) {
            reportStatus.visible = false;
          }
          if (reportStatus.id === 1) {
            reportStatus.visible = false;
          }
        }
        else if (reportStatusId === CLOSED) {
          if (reportStatus.id === OPEN) {
            reportStatus.name = 'OPEN AGAIN';
            reportStatus.visible = true;
          }
          if (reportStatus.id === 4) {
            reportStatus.visible = false;
          }
          else if (reportStatus.id === 3) {
            reportStatus.visible = true;
          }
          else if (reportStatus.id === 1) {
            reportStatus.visible = false;
          }
        }

        newReportStatusKeys.push(reportStatus)

      })
      return newReportStatusKeys;
    }

  },
  data(){
    return{
      editStatus:false,
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ],
      attachmentType:null,
      currentStatus:null,
      reportStatus:{
        id: null,
        deficiency:{
          id: null,
          report_status_log: {
            report_status_id: null,
            psc_deficiency_id: null
          }
        }
      },
    }
  },
  methods:{
    ...mapActions(['uploadPscAttachments','getPscAttachments']),
    correctiveActionAffectiveness(){
      if(!this.deficiency.corrective.is_corrective_action_affective){
        this.deficiency.corrective.is_corrective_action_effective = 0;
        this.deficiency.corrective.verification_effective_date=null;
        this.deficiency.corrective.is_verification_effective=0;
      }
    },

    async saveStatus() {
      let currStatusName = this.reportStatusKeysPerReport.filter(stat => stat.id === this.currentStatus)[0]?.name;
      const changeReportStatusName = this.reportStatusKeysPerReport.filter(stat => stat.id === this.reportStatus.deficiency.report_status_log.report_status_id)[0].name;
      currStatusName = !currStatusName ? '' : currStatusName;
      const verify = swal.fire({
        icon: 'question',
        title: 'CHANGE REPORT STATUS',
        html: `CHANGING <strong> ${currStatusName} </strong> STATUS TO <strong> ${changeReportStatusName} </strong> STATUS `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'PROCEED',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545'
      }).then(result => {
        return result;
      })

      if ((await verify).isConfirmed) {
        const response = await this.updatePscInfo(this.reportStatus);
        if (response) {
          this.editStatus=false;
          AlertService.successAlert('PSC Deficiency updated successfully', 'UPDATE PSC REPORT STATUS')
          this.resetDeficiency({id:this.deficiency.id});
        }
      }
    },

    async addCorrectiveAttachments(){
      const formData=new FormData();
      if(this.$refs.attachments.files.length > 0 ){
        Array.prototype.forEach.call(this.$refs.attachments.files,file=>{
          formData.append('attachments[]',file)
        })
        formData.append('id', this.getByKey(this.pscReport,'id'))
        formData.append('type[id]', this.getByKey(this.deficiency,'corrective.id'))
        formData.append('type[type_id]', this.attachmentType)
        const response = await this.uploadPscAttachments(formData);
        if(response){
          AlertService.successAlert('Attachment added successfully','Add Corrective Action Attachments')
          await this.getAttachments(this.attachmentType,this.deficiency,'corrective.id')
          this.$refs.attachments.value=null;
          this.$refs.closeCorrectiveAttachments.click()
        }
      }
    },

    setAttachmentType(type=2){
      this.attachmentType=type
    },

    async deleteCorrectiveAttachment(attachment){
      const file=attachment.attachment
      const type=attachment.type
      const response= await this.deleteFilePsc(file,type)
      if(response){
        this.getAttachments(type,this.deficiency,'corrective.id')
        AlertService.successAlert('Psc Corrective Attachment deleted successfully','DELETE CORRECTIVE ATTACHMENT')

      }
    },

    editReportStatus() {
      const reportStatusId = this.getByKey(this.deficiency,'report_status_log.report_status_id')
      this.currentStatus = reportStatusId
      this.reportStatus = {
        id: this.pscGet('id'),
        deficiency:{
          id:this.getByKey(this.deficiency,'id'),
          report_status_log: {
            report_status_id: reportStatusId,
            psc_deficiency_id: this.getByKey(this.deficiency,'id')
          }
        }
      }
      this.editStatus = true
    }

  },
}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
</style>
